import { Injectable } from '@angular/core';
import { BizEvent } from '@models/bizevent';
import { Guid } from "guid-typescript";
import { DataService } from '@services/data.service';
import { PluginService } from '@services/plugins.service';

const dynatrace = window.dynatrace;
const dtrum = window.dtrum;

const MS_IN_DAY = 864e5;

@Injectable()
export class BizEventService {

  constructor(
    private dataService: DataService,
    private pluginService: PluginService
  ) { }

  public createS02(searchTerm: string, fromDate: string, toDate: string, numberOfTravelers: string): BizEvent {
  	let attributes ={
  	  "searchTerm": searchTerm,
      "fromDate": fromDate,
      "toDate": toDate,
      "numberOfTravelers": numberOfTravelers
  	};
    return new BizEvent('com.easytravel.frontend.search-triggered', attributes);
  }

  public createS03(searchTerm: string, fromDate: string, toDate: string, numberOfTravelers: string, numberOfResults: number): BizEvent {
    let attributes ={
      "searchTerm": searchTerm,
      "fromDate": fromDate,
      "toDate": toDate,
      "numberOfTravelers": numberOfTravelers,
      "numberOfResults": numberOfResults
    };
    return new BizEvent('com.easytravel.frontend.search-result', attributes);
  }

  public createS04(product: string, journeyDuration: number, startPrice: number, reviewScore: string, resultPosition: number): BizEvent {
    let attributes ={
      "product": product,
      "journeyDuration": journeyDuration,
      "startPrice": startPrice,
      "reviewScore": reviewScore,
      "resultPosition": resultPosition
    };
    return new BizEvent('com.easytravel.funnel.product-view', attributes);
  }

  public createS05(entryPage: string, product: string, organizer: string, amount: number,
    reviewScore: string, arrivalDate: string, departureDate: string, journeyDuration: number,
    adultTravelers: number, childrenTravelers: number): BizEvent {

    let attributes ={
      "entryPage": entryPage,
      "product": product,
      "organizer": organizer,
      "amount": amount,
      "currency": "USD",
      "reviewScore": reviewScore,
      "arrivalDate": arrivalDate,
      "departureDate": departureDate,
      "journeyDuration": journeyDuration,
      "adultTravelers": adultTravelers,
      "childrenTravelers": childrenTravelers
    };
    return new BizEvent('com.easytravel.frontend.booking-process-triggered', attributes);
  }

  public createS06(userID: string, page: string, product: string, organizer: string, amount: number,
    reviewScore: string, arrivalDate: string, departureDate: string, journeyDuration: number,
    adultTravelers: number, childrenTravelers: number): BizEvent {

    let attributes ={
      "userId": userID,
      "page": page,
      "product": product,
      "organizer": organizer,
      "amount": amount,
      "currency": "USD",
      "reviewScore": reviewScore,
      "arrivalDate": arrivalDate,
      "departureDate": departureDate,
      "journeyDuration": journeyDuration,
      "adultTravelers": adultTravelers,
      "childrenTravelers": childrenTravelers
    };
    return new BizEvent('com.easytravel.frontend.login', attributes);
  }

  public createS08(page: string, product: string, organizer: string, amount: number,
    reviewScore: string, arrivalDate: string, departureDate: string, journeyDuration: number,
    adultTravelers: number, childrenTravelers: number, loyaltyStatus: string): BizEvent {

    let attributes ={
      "page": page,
      "product": product,
      "organizer": organizer,
      "amount": amount,
      "currency": "USD",
      "reviewScore": reviewScore,
      "arrivalDate": arrivalDate,
      "departureDate": departureDate,
      "journeyDuration": journeyDuration,
      "adultTravelers": adultTravelers,
      "childrenTravelers": childrenTravelers,
      "loyaltyStatus": loyaltyStatus
    };
    return new BizEvent('com.easytravel.funnel.booking-review', attributes);
  }

  public createS09(page: string, cCExpirationDate: string, product: string, organizer: string,
    amount: number, reviewScore: string, arrivalDate: string, departureDate: string,
    journeyDuration: number, adultTravelers: number, childrenTravelers: number, loyaltyStatus: string): BizEvent {

    let attributes ={
      "page": page,
      "ccExpirationDate": cCExpirationDate,
      "ccProvider": this.getOrGenerateProvider(),
      "product": product,
      "organizer": organizer,
      "amount": amount,
      "currency": "USD",
      "reviewScore": reviewScore,
      "arrivalDate": arrivalDate,
      "departureDate": departureDate,
      "journeyDuration": journeyDuration,
      "adultTravelers": adultTravelers,
      "childrenTravelers": childrenTravelers,
      "loyaltyStatus": loyaltyStatus
    };
    return new BizEvent('com.easytravel.funnel.booking-payment', attributes);
  }

  public createS10(page: string, failure: string[], product: string, organizer: string,
    amount: number, reviewScore: string, arrivalDate: string, departureDate: string,
    journeyDuration: number, adultTravelers: number, childrenTravelers: number, loyaltyStatus: string): BizEvent {

    let attributes ={
      "page": page,
      "failure": failure,
      "ccProvider": this.getOrGenerateProvider(),
      "product": product,
      "organizer": organizer,
      "amount": amount,
      "currency": "USD",
      "reviewScore": reviewScore,
      "arrivalDate": arrivalDate,
      "departureDate": departureDate,
      "journeyDuration": journeyDuration,
      "adultTravelers": adultTravelers,
      "childrenTravelers": childrenTravelers,
      "loyaltyStatus": loyaltyStatus
    };
    return new BizEvent('com.easytravel.funnel.booking-payment-failure', attributes);
  }

  public createS11(page: string, cCExpirationDate: string, product: string, organizer: string,
    amount: number, reviewScore: string, arrivalDate: string, departureDate: string,
    journeyDuration: number, adultTravelers: number, childrenTravelers: number, loyaltyStatus: string): BizEvent {

    let attributes ={
      "page": page,
      "ccExpirationDate": cCExpirationDate,
      "ccProvider": this.getOrGenerateProvider(),
      "product": product,
      "organizer": organizer,
      "amount": amount,
      "currency": "USD",
      "reviewScore": reviewScore,
      "arrivalDate": arrivalDate,
      "departureDate": departureDate,
      "journeyDuration": journeyDuration,
      "adultTravelers": adultTravelers,
      "childrenTravelers": childrenTravelers,
      "loyaltyStatus": loyaltyStatus
    };
    return new BizEvent('com.easytravel.funnel.booking-finished', attributes);
  }

  public createS12(userID: string): BizEvent {

    let attributes ={
      "userId": userID
    };
    return new BizEvent('com.easytravel.funnel.signin', attributes);
  }

  public createS13(userID: string, loyaltyStatus: string): BizEvent {

    let attributes ={
      "userId": userID,
      "loyaltyStatus": loyaltyStatus
    };
    return new BizEvent('com.easytravel.funnel.logout', attributes);
  }

  public createS14(): BizEvent {

    let attributes ={};
    return new BizEvent('com.easytravel.funnel.signup-triggered', attributes);
  }

  public createS15(firstName: string, lastName: string, email: string, state: string, city: string, street: string,
    door: string, phone: string): BizEvent {

    let attributes ={
      "firstName": firstName,
      "lastName": lastName,
      "email": email,
      "state": state,
      "city": city,
      "street": street,
      "door": door,
      "phone": phone
    };
    return new BizEvent('com.easytravel.funnel.signup', attributes);
  }

  public createS16(reason: string[]): BizEvent {
    let attributes ={
      "reason": reason
    };
    return new BizEvent('com.easytravel.funnel.signup-failure', attributes);
  }

  public sendBizEvent(event: BizEvent) {
    let bizEvents = this.pluginService.pluginScenarios.value.bizEvents;

    if (typeof dynatrace !== 'undefined' && typeof dynatrace['sendBizEvent'] === 'function' && bizEvents == true) {
      try {
        dynatrace.sendBizEvent(event.type, event.attributes);
      }
      catch (e) {
        dtrum.reportError(e);
      }
    }
  }

  public countJourneyDayCount(fromDate: number, toDate: number): number {
    return Math.round((toDate - fromDate) / MS_IN_DAY);
  }

  private getOrGenerateProvider() {
    let provider = this.dataService.provider;

    if (!provider) {
      const random = Math.floor(Math.random()*1000);

      if (random < 400) {
        this.dataService.provider = "Visa";
      }
      else if (random < 800) {
        this.dataService.provider = "Mastercard";
      }
      else {
        this.dataService.provider = "American Express";
      }

      provider = this.dataService.provider
    }

    return provider;
  }
}
