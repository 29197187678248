import { Component } from '@angular/core';
import { PluginService } from '@services/plugins.service';
import { v4 as uuidv4 } from 'uuid';
import { NavigationEnd, Router } from '@angular/router';
import { AdobeService } from '@services/adobe.service';
import { enableOpenTelemetry } from '../enable-open-telemetry';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private pluginService: PluginService, private router: Router, private adobeService: AdobeService) {
    pluginService.refreshPluginsList()
      .subscribe((plugins) => {
        if (plugins.includes('OpenTelemetry')) {
          enableOpenTelemetry(environment.serverUrl);
        }
      });

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        console.log('Routing: ');
        console.log(event);

        const pageUrl = window.location.hostname + window.location.pathname;

        switch ((event as NavigationEnd).url) {
        case '/home': {
          this.adobeService.setPageParams('easyTravel Home', pageUrl, 'home');
          break;
        }
        case '/search': {
          this.adobeService.setPageParams('easyTravel search results', pageUrl, 'saerch results');
          break;
        }
        case '/contact': {
          this.adobeService.setPageParams('easyTravel contact information', pageUrl, 'contact information');
          break;
        }
        case '/login': {
          this.adobeService.setPageParams('easyTravel login', pageUrl, 'login');
          break;
        }
        case '/signup': {
          this.adobeService.setPageParams('easyTravel signup', pageUrl, 'signup');
          break;
        }
        case '/error-page': {
          this.adobeService.setPageParams('easyTravel error-page', pageUrl, 'error-page');
          break;
        }
        default:
        {

          if ((event as NavigationEnd).url.includes('journeys')) {
            this.adobeService.setPageParams('easyTravel journey', pageUrl, 'journey');

            if ((event as NavigationEnd).url.includes('book')) {
              this.adobeService.setPageParams('easyTravel book journey', pageUrl, 'journey');
            }
          }

          break;
        }
        }
      }
    });

  }
}

declare global {
  interface Window {
    Intercom: Function;
  }
}

window.Intercom = (param) => {
  if (!localStorage.getItem('UUID')) {
    localStorage.setItem('UUID', uuidv4());
  }
  return localStorage.getItem('UUID');
};

