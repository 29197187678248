import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CardValidationResponse } from '@models/cardValidationResponse';
import { environment } from '../../environments/environment';

@Injectable()
export class CreditCardValidationService {

  private BASE_URL = environment.serverUrl + 'validate-creditcard';

  constructor(private http: HttpClient) { }

  validateCreditCard(creditCardNumber: string): Observable<CardValidationResponse> {
    return this.http.post(this.BASE_URL, { creditCardNumber })
      .pipe(map((response) => response as CardValidationResponse));
  }
}
