<app-main-layout class="search-page">
  <main class="page-section main-section">
    <div class="container">
      <div class="address-container">
        <div>
            <h2>World Headquarters</h2>
            <h3>Massachusetts</h3>
            <p>1601 Trapelo Road, Suite 116</p>
            <p>Waltham, MA 02451</p>
            <p>Phone: +1.781.530.1000</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/boston-dt-map.png">
        </div>
      </div>
      <div class="address-container">
        <div>
            <h3>Dynatrace Detroit</h3>
            <p>One Campus Martius</p>
            <p>Detroit, Michigan 48226</p>
            <p>United States of America</p>
            <p>Email: detroit@dynatrace.com</p>
            <p>Phone: (313) 227-7300</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/detroit-dt-map.png">
        </div>
      </div>
      <div class="address-container">
        <div>
            <h2>Linz Office</h2>
            <h3>Dynatrace Austria, Linz</h3>
            <p>Freistaedter Strasse 313 / 2</p>
            <p>4040 Linz</p>
            <p>Austria</p>
            <p>Email: info.austria@dynatrace.com</p>
            <p>Phone: +43 732 908208</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/linz-dt-map.png">
        </div>
      </div>
      <div class="address-container">
        <div>
            <h2>Spain (Development)</h2>
            <h3>Dynatrace SAU</h3>
            <p>Carrer de Pallars, 99</p>
            <p>08018 Barcelona</p>
            <p>Spain</p>
            <p>Email: bcn.office@dynatrace.com</p>
            <p>Phone: +34 93 348 21 64</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/barcelona-dt-map.png">
        </div>
      </div>
      <div class="address-container">
        <div>
            <h2>Poland (Development)</h2>
            <h3>Dynatrace sp. z.o.o.</h3>
            <p>Al. Grunwaldzka 411</p>
            <p>80-309 Gdansk</p>
            <p>Poland</p>
            <p>Email: gdn.office@dynatrace.com</p>
            <p>Phone: +48 58 524 7800</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/poland-dt-map.png">
        </div>
      </div>
      <div class="address-container">
        <div>
            <h2>California</h2>
            <p>951 Mariners Island Blvd. Suite 410</p>
            <p>San Mateo, CA 94404</p>
            <p>Phone: +1.650.436.6700</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/california-dt-map.png">
        </div>
      </div>
      <div class="address-container">
        <div>
            <h2>Austria (Development)</h2>
            <h3>Dynatrace Austria GmbH</h3>
            <p>Lakeside B08a</p>
            <p>9020 Klagenfurt am Wörthersee</p>
            <p>Austria</p>
            <p>Email: emeainfo@dynatrace.com</p>
            <p>Phone: +43 732 908208</p>
        </div>
        <div class="map-container">
          <img src="/assets/imgs/klagenfurt-dt-map.png">
        </div>
      </div>
    </div>
  </main>
</app-main-layout>