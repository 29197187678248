<app-main-layout class="search-page" *ngIf="journey">
  <div class="thumbnail" [style.background-image]="'url('+journey.images.currentPath+')'"></div>
  <main class="page-section">
    <h2 class="widget">{{ journey.destination }} is waiting for you</h2>

    <div class="steps-labels">
      <div class="step1 step-label" [ngClass]="step === 1 ? 'active' : ''">Login</div>
      <div class="step2 step-label" [ngClass]="step === 2 ? 'active' : ''">Review</div>
      <div class="step3 step-label" [ngClass]="step === 3 ? 'active' : ''">Payment</div>
      <div class="step4 step-label" [ngClass]="step === 4 ? 'active' : ''">Finish</div>
      <div class="step5 step-label" [ngClass]="step === 5 ? 'active' : ''">Summary</div>
    </div>

    <div class="steps">
      <app-login-form class="step step1" [ngClass]="step === 1 ? 'active' : ''"
        (authorized)="finishAuthorization($event)"></app-login-form>
      <div class="step step2" [ngClass]="step === 2 ? 'active' : ''" style="text-align: center;">
        <h2>{{ journey.name }}</h2>
        <p>{{ journey.start }} — {{ journey.destination }}</p>
        <p name="payment:date">{{ journey.fromDate | date }} — {{ journey.toDate | date }}</p>
        <p *ngIf="!ABtesting"><button name="payment:pay" (click)="finishReview()">Pay ${{ journey.amount }}</button></p>
      </div>
      <app-billing
        class="step step3" [ngClass]="step === 3 ? 'active' : ''"
        (finish)="finishBooking($event)"
        [price]="journey.amount"></app-billing>

      <div class="step step4 success-payment-message" [ngClass]="step === 4 ? 'active' : ''">
        <p>{{bookingConfirmed ? "Your journey was booked successfully! Open the summary and print out the ticket's.": bookingStatusError}}</p>
        <button id="summaryButton" (click)="onSummaryButtonClick()">Open summary and tickets</button>
      </div>
      <div class="step step5 journey-stats" [ngClass]="step === 5 ? 'active' : ''">
        <app-journey-summary [journey]="journey" [additionalEntries]="getSummaryAdditionalFields()"></app-journey-summary>
        <app-gallery [id]="journey.id.toString()" [images]="[journey.images.previousPath, journey.images.currentPath, journey.images.nextPath]"></app-gallery>
      </div>
    </div>

    <app-recently-booked #recentlyBooked class="widget" [realTimeUpdate]="realTimeUpdateRecentBookings"></app-recently-booked>
    <button *ngIf="step === 2 && ABtesting" name="payment:2pay" (click)="finishReview()">Pay ${{ journey.amount }}</button>
  </main>
</app-main-layout>
