import { Component, OnInit, Input } from '@angular/core';

const MS_IN_DAY = 864e5;

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {

  @Input() type: string;

  @Input() offer: any;
  public fOffer: any;

  constructor() { }

  ngOnInit() {
    const offer = this.offer;

    this.fOffer = {
      id: offer.id,
      title: offer.name,
      rating: offer.averageTotal,
      price: Math.floor(offer.amount),
      days: Math.round((offer.toDate - offer.fromDate) / MS_IN_DAY),
      images: [
        offer.images.currentPath,
        offer.images.previousPath,
        offer.images.nextPath
      ]
    };
  }

}
