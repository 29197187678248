import { Component, OnInit } from '@angular/core';
import {PluginService} from '@services/plugins.service';
import {ConfigService} from '@services/config.service';

@Component({
  selector: 'app-footnote',
  templateUrl: './footnote.component.html',
  styleUrls: ['./footnote.component.css']
})
export class FootnoteComponent implements OnInit {
  public phpEnabled = false;
  public blogUrl: string;

  constructor(private pluginService: PluginService, private configService: ConfigService) { }

  ngOnInit() {
    this.pluginService.pluginScenarios.subscribe(scenarios => {
      this.phpEnabled = scenarios.phpEnabled;
    });
    this.configService.getBlogUrl().subscribe((result: string) => {
      this.blogUrl = result;
    });
  }

}
