import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-offer-stat',
  templateUrl: './offer-stat.component.html',
  styleUrls: ['./offer-stat.component.css']
})
export class OfferStatComponent implements OnInit {

  @Input() stat: string;
  @Input() value: string|number;

  constructor() { }

  ngOnInit() {
  }

  getIconClass(): string {
    switch (this.stat) {
      case 'days':
        return 'icon-calendar';
      case 'price':
        return 'icon-price';
      case 'rating':
        return 'icon-rating';
      default:
        return '';
    }
  }

  getValue(): string {
    switch (this.stat) {
      case 'days':
        return this.value + ' ' + (this.value > 1 ? 'Days' : 'Day');
      case 'price':
        return 'From $ ' + this.formatPrice(+this.value);
      case 'rating':
        return this.value as string;
      default:
        return '';
    }
  }

  formatPrice(price: number): string|number {
    if (price < 1000) {
      return price;
    }

    const strPrice = price.toString();
    return strPrice.slice(0, -3) + '.' + strPrice.slice(-3);
  }

}
