import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JourneyService } from '@services/journey.service';
import { ConfigService } from '@services/config.service';
import { PluginService } from '@services/plugins.service';
import { BizEventService } from '@services/bizevent.service';

// Dyntrace error reporter
declare var dtrum: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit, OnDestroy {

  constructor(
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private pluginService: PluginService,
    private configService: ConfigService,
    private router: Router,
    private bizEventService: BizEventService
  ) { }

  public fromDate: string;
  public toDate: string;
  public numberOfPeople: string;
  public selectedLocation: string;
  public weatherApp = false;
  public nodejsurl: string;

  public subParams: any;

  public searchResults: any[] = [];
  public recommendedOffers: any[] = [];

  ngOnInit() {
    this.subParams = this.route.queryParams.subscribe(params => {
      this.fromDate = params.fromDate || '';
      this.toDate = params.toDate || '';
      this.numberOfPeople = params.numberOfPeople || '';
      this.selectedLocation = params.selectedLocation || '';

      this.updateJournerys();
    });

    this.journeyService.getRecommendation()
      .subscribe(journeys => {
        this.recommendedOffers = journeys.slice(0, 3);
      });

    this.pluginService.pluginScenarios.subscribe(scenarios => {
      this.weatherApp = scenarios.weatherApp;
    });

    this.configService.getNodejsUrl().subscribe((result: string) => {
      this.nodejsurl = result;
    });
  }

  ngOnDestroy() {
    this.subParams.unsubscribe();
  }

  updateJournerys = () => {
    this.journeyService
      .searchJourneysByText((this.selectedLocation || ''), this.fromDate, this.toDate)
      .subscribe(offers => {
        this.searchResults = offers.slice(0, 3);
        if (this.searchResults == null || this.searchResults.length === 0) {
          // custom error with user action
          if  (typeof dtrum !== 'undefined') {
            const a = dtrum.enterAction('Search action', 'Search');
            dtrum.reportCustomError(
              'Error',
              'No results found for query',
              `Happened when searching for journeys with parameters keyword:${this.selectedLocation} from:${this.fromDate} to:${this.toDate}`, a);
            dtrum.leaveAction(a);
          }
        }

        let length = (this.searchResults == null || this.searchResults.length === 0) ? 0 : this.searchResults.length;
        let bizEvent = this.bizEventService.createS03(this.selectedLocation, this.fromDate, this.toDate, this.numberOfPeople, length);
        this.bizEventService.sendBizEvent(bizEvent);
      });
  }

  get searchTitle(): string {
    let title = 'Search results for: ';
    if (this.selectedLocation) { title += this.selectedLocation; }
    if (this.fromDate) { title += ' from ' + this.fromDate; }
    if (this.toDate) { title += ' till ' + this.toDate; }
    if (this.numberOfPeople) {
      title += ' (' + this.numberOfPeople + (+this.numberOfPeople === 1 ? ' person' : ' persons') + ')';
    }
    return title;
  }

  get weatherLink(): string {
    return this.nodejsurl + 'current?loc=' + this.selectedLocation + '&days=3&tpl=small';
  }
}
