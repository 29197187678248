import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CreditCardValidationService } from '@services/creditcard-validation.service';
import { DataService } from '@services/data.service';
import { BizEventService } from '@services/bizevent.service';
import { Journey } from '@models/journey';
import { UserService } from '@services/user.service';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  @Input() price = 0;
  @Output() finish = new EventEmitter<any>();

  public autoFill = false;
  public number1 = '';
  public number2 = '';
  public number3 = '';
  public number4 = '';

  public month: number;
  public months: number[] = [];
  public year: number;
  public years: number[] = [];

  public cvc = '';

  public numberError = false;
  public cvcError = false;

  constructor(
    public creditcardService: CreditCardValidationService,
    private dataService: DataService,
    private bizEventService: BizEventService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.initCreditCardMonthAndYear();
  }

  toggleAutofill () {
    this.autoFill = !this.autoFill;
    if (this.autoFill) {
      this.autoFillCardDetails();
    }
  }

  private initCreditCardMonthAndYear () {
    const today = new Date();
    this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    this.month = (today.getMonth() + 1);

    this.year = (new Date()).getFullYear();
    for (let index = 0; index < 10; index++) {
      this.years.push(this.year + index);
    }
  }

  private autoFillCardDetails () {
    this.number1 = this.generateRandomNumberString(4);
    this.number2 = this.generateRandomNumberString(4);
    this.number3 = this.generateRandomNumberString(4);
    this.number4 = this.generateRandomNumberString(4);
    this.cvc = this.generateRandomNumberString(3);
    this.month = this.generateRandomMonth();
    this.year = this.generateRandomExpiryYear();
  }

  private generateRandomNumberString (range: number) {
    const a = Math.pow(10, range - 1);
    const b = a * 9;
    return Math.floor(a + Math.random() * b).toString();
  }

  private generateRandomMonth () {
    return this.months[Math.floor(Math.random() * this.months.length)];
  }

  private generateRandomExpiryYear () {
    return this.years[Math.floor(Math.random() * this.years.length)];
  }

  private validateCardSyntax() {
    const numberPartRegexp = /^\d{4}$/;
    let valid = true;

    this.numberError = false;
    this.cvcError = false;

    var errorMessage: string[];
    errorMessage = [];

    if (
      !this.number1.match(numberPartRegexp) ||
      !this.number2.match(numberPartRegexp) ||
      !this.number3.match(numberPartRegexp) ||
      !this.number4.match(numberPartRegexp)
    ) {
      this.numberError = true;
      valid = false;
      errorMessage.push("CC number not provided");
    }

    if (!this.cvc.match(/^\d{3,4}$/)) {
      this.cvcError = true;
      valid = false;
      errorMessage.push("CVC not provided");
    }

    if (this.numberError || this.cvcError) {
      let bizEvent = this.bizEventService.createS10(window.location.href, errorMessage ,
        this.dataService.journey.name, this.dataService.journey.tenant, this.dataService.journey.amount,
        this.dataService.journey.averageTotal, (new Date(this.dataService.journey.fromDate)).toJSON().slice(0,10),
        (new Date(this.dataService.journey.toDate)).toJSON().slice(0,10),
        this.bizEventService.countJourneyDayCount(this.dataService.journey.fromDate, this.dataService.journey.toDate),
        this.dataService.adults, this.dataService.children, this.userService.session().user.loyaltyStatus);
      this.bizEventService.sendBizEvent(bizEvent);
    }

    return valid;
  }

  book() {
    let bizEvent = this.bizEventService.createS09(window.location.href, this.year + "-" + this.month ,
      this.dataService.journey.name, this.dataService.journey.tenant, this.dataService.journey.amount,
      this.dataService.journey.averageTotal, (new Date(this.dataService.journey.fromDate)).toJSON().slice(0,10),
      (new Date(this.dataService.journey.toDate)).toJSON().slice(0,10),
      this.bizEventService.countJourneyDayCount(this.dataService.journey.fromDate, this.dataService.journey.toDate),
      this.dataService.adults, this.dataService.children, this.userService.session().user.loyaltyStatus);
    this.bizEventService.sendBizEvent(bizEvent);

    if (!this.validateCardSyntax()) {
      return;
    }

    const creditcard = this.number1 + this.number2 + this.number3 + this.number4;
    this.creditcardService.validateCreditCard(creditcard).subscribe(
      res => {
        if (res.valid) {
          this.finish.emit({
            creditcard,
            month: this.month,
            year: this.year,
            cvc: this.cvc
          });
        } else {
          this.numberError = false;
        }
      }
    );
  }

}
