<div class="main-widget recently-booked-widget">
  <h2>Recently booked</h2>

  <div class="book-widget" *ngFor="let booking of recentBookings">
    <img [src]="booking.journey.images.currentPath" class="book-thumbnail">
    <h3 class="book-title">{{ booking.journey.destination }}</h3>
    <div class="book-stats">
        <app-offer-stat stat="days" [value]="getBookingDurationInDays(booking)"></app-offer-stat>
        <app-offer-stat stat="price" [value]="getFormattedPrice(booking)"></app-offer-stat>
      <app-offer-stat stat="rating" [value]="booking.journey.averageTotal"></app-offer-stat>
    </div>
  </div>
</div>
