import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Journey } from '@models/journey';
import { environment } from '../../environments/environment';
import { DataService } from '@services/data.service';

@Injectable()
export class JourneyService {

  private BASE_URL = environment.serverUrl + 'journeys/';
  private searchResults = new BehaviorSubject<Journey[]>([]);
  public currentSearchResults = this.searchResults.asObservable();

  constructor(private http: HttpClient, private dataService: DataService) { }

  public getJourneyById (id: number): Observable<Journey> {
    return this.http.get(this.BASE_URL + id)
      .pipe
      (
        catchError(this.handleError('getJourneyById', null)),
        map((response) => response as Journey)
      );
  }

  public getRecommendation(): Observable<Journey[]> {
    return this.http.get(this.BASE_URL + 'recommendation')
      .pipe
      (
        catchError(this.handleError('getRandomJourneys', [])),
        map((response) => response as Journey[])
      );
  }

  public getSpecialOffers(): Observable<Journey[]> {
    return this.http.get(this.BASE_URL + 'special-offers')
      .pipe
      (
        catchError(this.handleError('special-offers', [])),
        map((response) => response as Journey[])
      );
  }

  searchJourneysByText(text: string, from: string, to: string): Observable<Journey[]> {
    // if all parameters are empty return empty array
    if (text.length === 0 && from === null && to === null) {
      return new Observable<Journey[]>();
    }
    const url = this.getSearchJourneyUrl(text, from, to);
    return this.http.get(url)
      .pipe(
        catchError(this.handleError('searchJourneysByText', [])),
        map( (response) => {
          this.searchResults.next(response as Journey[]);
          return response as Journey[];
        })
      );
  }

  private getSearchJourneyUrl(text: string, from: string, to: string): string {

    let url = this.BASE_URL + '?match=' + text;

    if (from !== null && to === null) {
      url += '&from=' + from;
    } else if (from === null && to !== null) {
      url += '&to=' + to;
    } else {
      url += '&from=' + from + '&to=' + to;
    }
    return url;
  }

  public getJourneyPostCount(id: number): Observable<number> {
    return this.http.get(this.BASE_URL + id + '/post-count').pipe(
        catchError(this.handleError('areJourneyPostPresent', {})),
        map(postsPresent => postsPresent as number)
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.log(error); // log to console instead
      this.dataService.errorMessage = error.error;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
