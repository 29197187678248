<header [ngClass]="openMenu? 'opened-menu page-section header-section':'page-section header-section'">
  <a class="header-banner" routerLink="/home">
    <img class="logo" src="/assets/imgs/easytravel-logo.svg">
  </a>

  <nav #headerMenu (click)="openMenu = !openMenu;" class="header-navigation">
    <a *ngIf="isAuthorized()" class="greeting">Hi, {{ getUsername() }}</a>
    <a id="header:specialoffers" [class.active]="isActiveRoute('/home')" routerLink="/home">Special offers</a>
    <a id="header:contact" [class.active]="isActiveRoute('/contact')" routerLink="/contact">Contact</a>
    <a id="header:login" [class.active]="isActiveRoute('/login')" routerLink="/login" *ngIf="!isAuthorized()">Login</a>
    <a id="header:signup" [class.active]="isActiveRoute('/signup')" routerLink="/signup" *ngIf="!isAuthorized()">Sign
      up</a>
    <a id="header:logout" *ngIf="isAuthorized()" (click)="logout()">Logout</a>
  </nav>
</header>