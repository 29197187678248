import { NgModule, ErrorHandler, Injectable } from '@angular/core';

// Dyntrace error reporter
declare var dtrum: any;

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  handleError(error) {
    if  (typeof dtrum !== 'undefined') {
      dtrum.reportError(error);
    }
    console.error(error);
  }
}
