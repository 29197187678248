import {AbstractControl} from '@angular/forms';

export class CustomSignupValidations {

  static MatchFieldValidations(AC: AbstractControl) {
    const password = AC.get('password').value;
    const confirmPassword = AC.get('confirmPassword').value;
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors( {MatchPassword: true} );
    }

    const email = AC.get('email').value;
    const confirmEmail = AC.get('confirmEmail').value;
    if (email !== confirmEmail) {
      AC.get('confirmEmail').setErrors( {MatchEmail: true} );
    }
  }
}
