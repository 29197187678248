export class ProblemScenarios {
    realTimeUpdateRecentBooking: boolean;
    max3Travelers: boolean;
    ABtesting: boolean;
    scrollOnReview: boolean;
    weatherApp: boolean;
    phpEnabled: boolean;
    bizEvents: boolean;
    constructor () {
        this.realTimeUpdateRecentBooking = false;
        this.max3Travelers = false;
        this.ABtesting = false;
        this.scrollOnReview = false;
        this.weatherApp = false;
        this.phpEnabled = false;
        this.bizEvents = false;
    }
}
