<div class="hero-wrapper">
  <section class="page-section hero-section">
    <h1 class="hero-title">easyTravel</h1>
    <span class="hero-slogan-begin">Collect moments</span>
    <span class="hero-slogan-end">not things.</span>
    <form class="hero-form" (ngSubmit)="searchResults()">
      <div class="hero-form-field hero-form-search">
        <input id="search:destination" placeholder="Asia" class="hero-form-input icon-search" name="location"
               auto-complete autocomplete="off" [(ngModel)]="selectedLocation"
               [source]="locations.bind(this)"
               value-formatter="name"
               list-formatter="name">
      </div>
      <div class="hero-form-field hero-form-arrive">
        <input id="search:fromdate" placeholder="yyyy-mm-dd" class="hero-form-input icon-calendar" name="fromDate"
               [(ngModel)]="fromDate" [dpDayPicker]="datePickerConfig" theme="dp-material"
               autocomplete="off">
      </div>
      <div class="hero-form-field hero-form-depart">
        <input id="search:todate" placeholder="yyyy-mm-dd" class="hero-form-input icon-calendar" name="toDate"
               [(ngModel)]="toDate" [dpDayPicker]="datePickerConfig" theme="dp-material"
               autocomplete="off">
      </div>
      <div class="hero-form-field hero-form-guests">
        <input id="search:travellers" placeholder="2 travellers" class="hero-form-input icon-community" name="numberOfPeople"
               type="number" [(ngModel)]="numberOfPeople" min="1"
               autocomplete="off">
      </div>
      <div class="hero-form-field hero-form-submit">
        <button name="search:submit" type="submit" class="hero-form-input">Search</button>
      </div>
      <div class="hero-form-field hero-form-submit">
        <button name="search:clear" type="reset" class="hero-form-input">Clear</button>
      </div>
    </form>
  </section>
</div>
