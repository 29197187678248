export class User {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
    creditCardNumber: number;
    verificationNumber: number;
    expirationMonth: string;
    expirationYear: number;
    state: string;
    city: string;
    street: string;
    door: string;
    phone: string;
    loyaltyStatus: string;

    constructor(firstname?: string, lastname?: string, email?: string, password?: string,
                creditCardNumber?: number, verificationNumber?: number, expirationMonth?: string,
                expirationYear?: number, loyaltyStatus?: string, state?: string, city?: string, street?: string,
                door?: string, phone?: string) {
        this.firstname = firstname || '';
        this.lastname = lastname || '';
        this.email = email || '';
        this.password = password || '';
        this.creditCardNumber = creditCardNumber || null;
        this.verificationNumber = verificationNumber || null;
        this.expirationMonth = expirationMonth || '';
        this.expirationYear = expirationYear || null;
        this.state = state || '';
        this.city = city || '';
        this.street = street || '';
        this.door = door || '';
        this.phone = phone || '';
        this.loyaltyStatus = loyaltyStatus || '';
    }
}
