<div>
  <div class="card-panel">
    <h2 class="card-title">Payment Details
      <div title="Auto fill credit card">
        <fa-icon *ngIf="autoFill; else unlockAutofill" class="autofill-icon" icon="unlock" (click)="toggleAutofill()"></fa-icon>
        <ng-template #unlockAutofill>
          <fa-icon class="autofill-icon" icon="lock" (click)="toggleAutofill()"></fa-icon>
        </ng-template>
      </div>
    </h2>

    <label>CARD NUMBER</label>
    <div [class]="['form-row', 'card-number', numberError ? 'error' : ''].join(' ')">
      <input id="creditCard:first4digits" [(ngModel)]="number1" class="num" type="text" size="4" maxlength="4" placeholder="0000">
      <input id="creditCard:second4digits" [(ngModel)]="number2" class="num" type="text" size="4" maxlength="4" placeholder="0000">
      <input id="creditCard:third4digits"[(ngModel)]="number3" class="num" type="text" size="4" maxlength="4" placeholder="0000">
      <input id="creditCard:fourth4digits" [(ngModel)]="number4" class="num" type="text" size="4" maxlength="4" placeholder="0000">
    </div>

    <label>EXPIRATION DATE</label>
    <div class="form-row exp">
      <select [(ngModel)]="month">
        <option value="1">January</option>
        <option value="2">February</option>
        <option value="3">March</option>
        <option value="4">April</option>
        <option value="5">May</option>
        <option value="6">June</option>
        <option value="7">July</option>
        <option value="8">August</option>
        <option value="9">September</option>
        <option value="10">October</option>
        <option value="11">November</option>
        <option value="12">December</option>
      </select>
      <select [(ngModel)]="year">
        <option *ngFor="let y of years" [value]="y">{{y}}</option>
      </select>
      <input id="creditCard:cvc" [(ngModel)]="cvc" [class]="['cvc', cvcError ? 'error' : ''].join(' ')" type="text" class="num" placeholder="CVC" size="4"
        maxlength="4">
    </div>

    <div class="form-row">
      <button name="creditCard:submit" type="button" (click)="book()" class="place-order">Book Journey for ${{price}}</button>
    </div>
  </div>
</div>
