import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  public isForecast = false;
  private _link = '';

  @Input()
  set link(link: string) {
    this._link = link;
    this.isForecast = this._link.indexOf('tpl=medium') >= 0;
  }

  get link(): string {
    return this._link;
  }

  constructor() { }

  ngOnInit() {
  }
}
