import { Injectable } from '@angular/core';
import { Journey } from '@models/journey';


@Injectable()
export class DataService {
  public errorMessage: string;
  public travellers: string;
  public journey: Journey;
  public adults: number;
  public children: number;
  public provider: string;

  public determineTravellers() {
    this.adults = 1;
    this.children = 0;

    if (this.travellers == "4" || this.travellers == "5" || this.travellers == "6") {
      this.adults = 2;
    }
    if (this.travellers == "2" || this.travellers == "5") {
      this.children = 1;
    }
    else if (this.travellers == "3" || this.travellers == "6") {
      this.children = 2;
    }
  }
}
