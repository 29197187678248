import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeComponent } from '@components/home/home.component';
import { SearchComponent } from '@components/search/search.component';
import { JourneyComponent } from '@components/journey/journey.component';
import { BookJourneyComponent } from '@components/book-journey/book-journey.component';
import { ContactComponent } from '@components/contact/contact.component';
import { LoginComponent } from '@components/login/login.component';
import { SignupComponent } from '@components/signup/signup.component';
import { ErrorPageComponent } from '@components/error-page/error-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'search', component: SearchComponent },
  { path: 'journeys/:id', component: JourneyComponent },
  { path: 'journeys/:id/book', component: BookJourneyComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'error-page', component: ErrorPageComponent }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
