import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { JourneyService } from '@services/journey.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PluginService } from '@services/plugins.service';
import { Subscription } from 'rxjs/Subscription';
import { DataService } from '@services/data.service';
import { DatePipe } from '@angular/common';
import { ConfigService } from '@services/config.service';
import { AdobeService } from '@services/adobe.service';
import { BizEventService } from '@services/bizevent.service';

@Component({
  selector: 'app-journey',
  templateUrl: './journey.component.html',
  styleUrls: ['./journey.component.css']
})
export class JourneyComponent implements OnInit, OnDestroy {

  public bookNowForm: FormGroup;
  public journey: any;
  public journeyPostCount: number;
  public realTimeUpdateRecentBookings = false;
  public max3Travelers = false;
  public disableBookNow: boolean;
  public weatherApp = false;
  public nodejsurl: string;
  private paramsSub: Subscription;
  private formChangesSubscription: Subscription;

  public blogUrl: string;
  public phpEnabled = false;

  constructor(
    private journeyService: JourneyService,
    private pluginService: PluginService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private configService: ConfigService,
    private datePipe: DatePipe,
    private adobeService: AdobeService,
    private bizEventService: BizEventService
  ) { }

  ngOnInit() {
    this.bookNowForm = this.fb.group({
      travelers: new FormControl('1'),
    });

    this.dataService.travellers = '1';

    this.paramsSub = this.route.params.subscribe(params => {
      this.adobeService.setSelectedJourney(params.id);

      this.journeyService.getJourneyById(params.id)
        .subscribe(journey => {
          if (!journey) {
            this.router.navigate(['/error-page']);
            return;
          }
          if (journey.error) {
            this.router.navigate(['/search']);
          } else {
            this.journey = journey;
            this.journeyService.getJourneyPostCount(journey.id).subscribe(postsPresent => {
              this.journeyPostCount = postsPresent;
            });

            let bizEvent = this.bizEventService.createS04(
              this.journey.name, this.bizEventService.countJourneyDayCount(this.journey.fromDate, this.journey.toDate),
              this.journey.amount, this.journey.averageTotal, 1);
            this.bizEventService.sendBizEvent(bizEvent);
          }
        });
    });

    this.pluginService.pluginScenarios.subscribe(scenarios => {
      this.realTimeUpdateRecentBookings = scenarios.realTimeUpdateRecentBooking;
      this.max3Travelers = scenarios.max3Travelers;
      this.weatherApp = scenarios.weatherApp;
    });

    this.formChangesSubscription = this.bookNowForm.valueChanges.subscribe(selections => {
      this.dataService.travellers = selections.travelers;

      this.adobeService.setJourneyPassengers(selections.travelers);

      if (this.max3Travelers && selections.travelers === '6') {
        throw new Error('TypeError: invalid arguments');
      }
    });

    this.configService.getNodejsUrl().subscribe((result: string) => {
      this.nodejsurl = result;
    });
    this.configService.getBlogUrl().subscribe((result: string) => {
      this.blogUrl = result;
    });
    this.pluginService.pluginScenarios.subscribe(scenarios => {
      this.phpEnabled = scenarios.phpEnabled;
    });
  }

  ngOnDestroy() {
    this.paramsSub.unsubscribe();
    this.formChangesSubscription.unsubscribe();
  }

  bookJourney() {
    this.dataService.determineTravellers();
    let bizEvent = this.bizEventService.createS05(window.location.href, this.journey.name, this.journey.tenant,
      this.journey.amount, this.journey.averageTotal, (new Date(this.journey.fromDate)).toJSON().slice(0,10),
      (new Date(this.journey.toDate)).toJSON().slice(0,10),
      this.bizEventService.countJourneyDayCount(this.journey.fromDate, this.journey.toDate), this.dataService.adults,
      this.dataService.children);
    this.bizEventService.sendBizEvent(bizEvent);

    this.router.navigate(['/journeys/' + this.journey.id + '/book']);
  }

  get weatherLink(): string {
    const dateFrom = new Date(this.journey.fromDate);
    const differenceDays = (this.journey.toDate - this.journey.fromDate) / 86400000;
    // tslint:disable-next-line:max-line-length
    return `${this.nodejsurl}forecast?loc=${this.journey.destination}&days=${differenceDays}&date=${this.datePipe.transform(dateFrom, 'yyyy-MM-dd')}&tpl=medium`;
  }
}
