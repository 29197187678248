import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.css']
})
export class OffersListComponent implements OnInit {

  @Input() type: string;
  @Input() title: string;

  private _offers: any[];
  @Input()
  set offers(offers: any[]) {
    this._offers = offers;
  }
  get offers(): any[] {
    return this._inline ? this._offers.slice(0, 3) : this._offers;
  }

  private _inline: boolean;
  @Input()
  set inline(inline: boolean) {
    this._inline = inline;
  }
  get inline(): boolean {
    return this._inline;
  }

  constructor() { }

  ngOnInit() {
  }

}
