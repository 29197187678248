import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CustomSignupValidations } from './custom-signup-validations';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { DataService } from '@services/data.service';
import { BizEventService } from '@services/bizevent.service';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})

export class SignupFormComponent implements OnInit {

  @Output() authorized = new EventEmitter<any>();

  private formSubmitAttempt: boolean;
  form;
  errorMessage: any;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dataService: DataService,
    private bizEventService: BizEventService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
        firstName: ['', Validators.compose([
          Validators.required,
          Validators.minLength(3)
        ])],
        lastName: ['', Validators.required],
        email: ['', Validators.compose([
          Validators.required,
          Validators.email
        ])],
        confirmEmail: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
        state: [''],
        city: [''],
        street: [''],
        door: [''],
        phone: ['']
    },
      {
        validator: CustomSignupValidations.MatchFieldValidations
      });

    this.dataService.errorMessage = null;
    this.errorMessage = null;

    let bizEvent = this.bizEventService.createS14();
    this.bizEventService.sendBizEvent(bizEvent);
  }

  isFieldValid(field: string) {
    return (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt);
  }

  onSubmit(): void {
    const user = this.form.value;

    let bizEvent = this.bizEventService.createS15(user.firstName, user.lastName, user.email, user.state, user.city,
      user.street, user.door, user.phone);
    this.bizEventService.sendBizEvent(bizEvent);

    this.formSubmitAttempt = true;
    if (!this.form.valid) {
      var errorMessage: string[];
      errorMessage = [];

      if (!this.form.get("firstName").valid) {
        errorMessage.push("firstname not provided");
      }
      if (!this.form.get("lastName").valid) {
        errorMessage.push("lastName not provided");
      }
      if (!this.form.get("email").valid) {
        errorMessage.push("email not provided");
      }
      if (!this.form.get("password").valid) {
        errorMessage.push("password not provided");
      }
      if (this.form.get("email").valid && !this.form.get("confirmEmail").valid) {
        errorMessage.push("email does not match repeat email");
      }
      if (this.form.get("password").valid && !this.form.get("confirmPassword").valid) {
        errorMessage.push("password does not match repeat password");
      }

      let errorBizEvent = this.bizEventService.createS16(errorMessage);
      this.bizEventService.sendBizEvent(errorBizEvent);

      return;
    }

    const signupUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: user.password,
      state: user.state,
      city: user.city,
      street: user.street,
      door: user.door,
      phone: user.phone
    };

    this.userService.signin(signupUser)
      .subscribe(() => {
        this.errorMessage = this.dataService.errorMessage;
        if (!this.errorMessage) {
          this.router.navigate(['/login']);
        }
      });
  }

}
