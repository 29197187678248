  import { Injectable } from '@angular/core';
  import { HttpClient, HttpParams } from '@angular/common/http';
  import { Observable, of } from 'rxjs';
  import { catchError, map } from 'rxjs/operators';
  import { User } from '@models/user';
  import { Loginuser } from '@models/loginUser';
  import { environment } from '../../environments/environment';
  import { DataService } from '@services/data.service';

  const BASE_URL = environment.serverUrl;
  const SESSION_KEY = 'easytravel/session';

  const saveSession = session => {
    localStorage.setItem(SESSION_KEY, JSON.stringify(session));
  };

  const deleteSession = () => {
    localStorage.removeItem(SESSION_KEY);
  };

  const loadSession = () => {
    const storedSession = localStorage.getItem(SESSION_KEY);
    const session = storedSession ? JSON.parse(storedSession) : null;
    return session;
  };

  @Injectable()
  export class UserService {

    constructor(private http: HttpClient, private dataService: DataService) { }

    authenticate(loginUser: Loginuser): Observable<any> {
      return this.http.post(BASE_URL + 'login', loginUser)
        .pipe
        (
          map(response => this.createSession(response)),
          map(session => this.extractUser(session)),
          map(user => this.mapToUser(user))
        );
    }

    signin(newUser): Observable<any> {
      return this.http.post(BASE_URL + 'signin', newUser)
      .pipe
      (
        catchError(this.handleError('signin'))
      );
    }

    private handleError<T> (operation = 'operation', result?: T) {
      return (error: any): Observable<T> => {
        // if the error is the generated 403, then report it
        if (error.status === 403) {
          this.dataService.errorMessage = error.error.error;
        }

        // Let the app keep running by returning an empty result.
        return of(result as T);
      };
    }

    searchUsers(match: string): Observable<Loginuser[]> {
      if (match) {
        const options =  { params: new HttpParams().set('match', match) };
        return this.http.get(BASE_URL + 'users', options)
          .pipe(map(response => response as Loginuser[]));
      } else {
        return this.http.get(BASE_URL + 'users/random')
          .pipe(map(response => response as Loginuser[]));
      }
    }

    logout() {
      deleteSession();
      return Promise.resolve();
    }

    isAuthorized(): boolean {
      return !!loadSession();
    }

    session(): any {
      return loadSession();
    }

    private createSession(session) {
      saveSession(session);
      return session;
    }

    private extractUser(session) {
      return session.user;
    }

    private mapToUser(user) {
      const firstname = user.firstname || '';
      const lastname = user.lastname || '';
      const email = user.email || '';
      const password = user.password || '';
      const ccNumber = user.creditCardNumber || null;
      const vNumber = user.verificationNumber || null;
      const eMonth = user.expirationMonth || '';
      const eYear = user.expirationYear || null;
      const loyaltyStatus = user.loyaltyStatus || '';

      return new User(firstname, lastname, email, password, ccNumber, vNumber, eMonth, eYear, loyaltyStatus);
    }
  }
