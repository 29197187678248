import { Component } from '@angular/core';

declare var dtrum: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  endSession() {
    if (typeof dtrum !== 'undefined') { dtrum.endSession(); }
  }
}
