import { Component, OnInit, Input } from '@angular/core';

// Dyntrace error reporter
declare var dtrum: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  @Input() images: string[];

  constructor() { }

  ngOnInit() {
  }

  onSlide(event) {
    // custom error without user action
    if  (typeof dtrum !== 'undefined') {
      dtrum.reportCustomError('TypeError', 'Cannot read property \'loaded\' of undefined', 'Missing or wrong image');
    }
  }

}
