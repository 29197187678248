import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { JourneyService } from '@services/journey.service';
import { UserService } from '@services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BookingService } from '@services/booking.service';
import { PluginService } from '@services/plugins.service';
import { BookingRequest } from '@models/bookingRequest';
import { DataService } from '@services/data.service';
import { Journey } from '@models/journey';
import { User } from '@models/user';
import { AdobeService } from '@services/adobe.service';
import { BizEventService } from '@services/bizevent.service';

@Component({
  selector: 'app-book-journey',
  templateUrl: './book-journey.component.html',
  styleUrls: ['./book-journey.component.css']
})
export class BookJourneyComponent implements OnInit, OnDestroy {

  @ViewChild('recentlyBooked', { read: ElementRef })
  recentlyBooked: ElementRef;

  public journey: Journey;
  public sub: any;
  public step = 1;
  public bookingConfirmed = false;
  public bookingStatusError: string;
  public realTimeUpdateRecentBookings = false;
  public ABtesting = false;
  public scrollOnReview = false;
  public user: any;

  constructor(
    private journeyService: JourneyService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private bookingService: BookingService,
    private pluginService: PluginService,
    private dataService: DataService,
    private adobeService: AdobeService,
    private bizEventService: BizEventService
  ) { }

  ngOnInit() {
    if (this.userService.isAuthorized()) {
      this.step++;
    }

    this.sub = this.route.params.subscribe(params => {
      this.journeyService.getJourneyById(params.id)
        .subscribe(journey => {
          if (journey.error) {
            this.router.navigate(['/search']);
          } else {
            this.journey = journey;
            this.dataService.journey = journey;
            this.adobeService.setJourneyPrice(journey.amount.toString());
            this.dataService.determineTravellers();

            if (this.step == 2) {
              this.sendStep2Event();
            }
          }
        });
    });

    this.pluginService.pluginScenarios.subscribe(scenarios => {
      this.realTimeUpdateRecentBookings = scenarios.realTimeUpdateRecentBooking;
      this.ABtesting = scenarios.ABtesting;
      this.scrollOnReview = scenarios.scrollOnReview;
    });

    console.log('BookJourney ngOnInit');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  finishAuthorization(event: object) {
    let bizEvent = this.bizEventService.createS06(this.userService.session().user.email, window.location.href,
      this.journey.name, this.journey.tenant, this.journey.amount, this.journey.averageTotal,
      (new Date(this.journey.fromDate)).toJSON().slice(0,10), (new Date(this.journey.toDate)).toJSON().slice(0,10),
      this.bizEventService.countJourneyDayCount(this.journey.fromDate, this.journey.toDate), this.dataService.adults,
      this.dataService.children);
    this.bizEventService.sendBizEvent(bizEvent);

    this.step++;

    this.sendStep2Event();
  }

  private sendStep2Event() {
    let bizEvent = this.bizEventService.createS08(window.location.href, this.journey.name, this.journey.tenant,
      this.journey.amount, this.journey.averageTotal, (new Date(this.journey.fromDate)).toJSON().slice(0,10),
      (new Date(this.journey.toDate)).toJSON().slice(0,10),
      this.bizEventService.countJourneyDayCount(this.journey.fromDate, this.journey.toDate), this.dataService.adults,
      this.dataService.children, this.userService.session().user.loyaltyStatus);
    this.bizEventService.sendBizEvent(bizEvent);
  }

  finishReview() {
    this.step++;
    if (this.scrollOnReview) {
      this.recentlyBooked.nativeElement.scrollIntoView();
    }
  }

  finishBooking(event: any) {
    this.user = this.userService.session().user;
    const bookingReq = new BookingRequest(this.journey.id.toString(), this.user.email,
      event.creditcard, this.journey.amount.toString(), this.dataService.travellers);

    this.bookingService.doBooking(bookingReq).subscribe(res => {
      this.step++;

      let bizEvent = this.bizEventService.createS11(window.location.href, event.year + "-" + event.month,
        this.journey.name, this.journey.tenant, this.journey.amount, this.journey.averageTotal,
        (new Date(this.journey.fromDate)).toJSON().slice(0,10), (new Date(this.journey.toDate)).toJSON().slice(0,10),
        this.bizEventService.countJourneyDayCount(this.journey.fromDate, this.journey.toDate), this.dataService.adults,
        this.dataService.children, this.userService.session().user.loyaltyStatus);
      this.bizEventService.sendBizEvent(bizEvent);

      const errorMessage = this.dataService.errorMessage;
      if (res.bookingId) {
        this.bookingConfirmed = true;
      } else if (errorMessage) {
        this.bookingStatusError = errorMessage;
        this.dataService.errorMessage = null;
        throw errorMessage;
      }
    });
  }

  getSummaryAdditionalFields(): {name: string, value: string}[] {
    return !this.user ? [] : [{name: 'Name', value: this.user.firstName}, {name: 'Surname', value: this.user.lastName},
      {name: 'Email', value: this.user.email}, {name: 'Address', value: this.user.door + ' ' + this.user.street}];
  }

  onSummaryButtonClick() {
    if (this.bookingConfirmed) {
      this.step++;
    }
  }
}
