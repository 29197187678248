<app-main-layout class="search-page">
  <app-hero></app-hero>
  <main class="page-section main-section">
    <app-offers-list
      class="search-widget"
      inline="true"
      [title]="searchTitle"
      [offers]="searchResults"
	  type="search"></app-offers-list>
	  
	<div *ngIf="searchResults?.length > 0 && weatherApp" class="search-widget">
      <h2>Weather forecast for {{selectedLocation}}:</h2>
      <weather [link]="weatherLink"></weather>
  	</div>

    <app-offers-list
      class="search-widget"
      inline="true"
      title="Recommendations"
      [offers]="recommendedOffers"
	  type="recommendation"></app-offers-list>
  </main>
</app-main-layout>
