<div class="footnote-wrapper">
  <aside class="page-section footnote-section">
    <div class="footnote-widget recent-post-section">
      <h2>Latest blog entry</h2>

      <h3>A Uniquely Kiwi Adventure</h3>
      <p class="recent-post-meta">Apr 5, 2017, by Kathleen Peterson</p>

      <p>Today's post is brought to you by easyTravel Student Ambassador, Kathleen Peterson. Kathleen is a student at Michigan State...
        <a *ngIf="phpEnabled" [href]="blogUrl" name="blogLink">more</a>
      </p>
    </div>
    <div class="footnote-widget subscribe-section">
      <h2>Never miss a story</h2>

      <p>Sign up for our Newsletter and stay up to date!</p>

      <form class="subscribe-form">
        <input placeholder="your@email.com">
        <button type="submit">Sign up</button>
      </form>
    </div>
    <div class="footnote-widget contacts-section">
      <h2>Where to find us</h2>

      <p>Freistaedter Strasse 313, 4040 Linz, Austria</p>
      <address><a href="#">emeainfo@dynatrace.com</a></address>

      <p>Omnilogy Sp. z.o.o. Al. Grunwaldzka 411 80-309 Gdansk, Poland</p>
      <address><a href="#">dynatrace@omnilogy.com</a></address>
    </div>
  </aside>
</div>
