<form [formGroup]="form" (submit)="onSubmit()">
  <label>
    First Name *
    <input formControlName="firstName" [class]="isFieldValid('firstName')? 'invalid' : ''">
  </label>

  <label>
    Last Name *
    <input formControlName="lastName" [class]="isFieldValid('lastName')? 'invalid' : ''">
  </label>

  <label>
    Email *
    <input formControlName="email" [class]="isFieldValid('email')? 'invalid' : ''">
  </label>

  <label for="confirmEmail">Email (repeat) *
    <input formControlName="confirmEmail" [class]="isFieldValid('confirmEmail')? 'invalid' : ''">
  </label>

  <label>
    Password *
    <input formControlName="password" type="password" [class]="isFieldValid('password')? 'invalid' : ''">
  </label>

  <label>
    Password (repeat) *
    <input formControlName="confirmPassword" type="password" [class]="isFieldValid('confirmPassword')? 'invalid' : ''">
  </label>

  <label>
    State
    <input formControlName="state">
  </label>

  <label>
    City
    <input formControlName="city">
  </label>

  <label>
    Street
    <input formControlName="street">
  </label>

  <label>
    Door
    <input formControlName="door">
  </label>

  <label>
    Phone
    <input formControlName="phone">
  </label>

  <label *ngIf="errorMessage" class="myError">
    {{errorMessage}}
  </label>
  
  <button>Sign Up</button>
</form>

