<form class="login-form" (submit)="login(username.value, password.value)">
  <input id="loginForm:username" type="text" name="email" placeholder="email" auto-complete  autocomplete="off"
    [(ngModel)]="selectedLogin" [source]="autoFillLogins.bind(this)"
    value-formatter="name" list-formatter="name" (valueChanged)="autoLogin($event)"
    [no-match-found-text]="autoFill? 'No match found' : 'Unlock autofill for suggestions'"
    autocomplete="off" #username>

  <input id="loginForm:password" type="password" name="password" placeholder="password" #password>

  <fa-icon *ngIf="autoFill; else unlockAutofill" class="lockIcon" icon="unlock" (click)="toggleAutofill()"></fa-icon>
  <ng-template #unlockAutofill>
    <fa-icon class="lockIcon" icon="lock" (click)="toggleAutofill()"></fa-icon>
  </ng-template>

  <button id="loginForm:submit" type="submit">Sign In</button>
</form>
