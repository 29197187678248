import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProblemScenarios } from '@models/problemScenarios';
import { environment } from '../../environments/environment';

const PROBLEM_PATTERNS = {
    webPageRequestSpamming: 'WebPageRequestSpamming',
    max3Travelers : 'JsErrorWhenSelectingTravelers',
    ABtesting: 'UsabilityIssue',
    scrollOnReview: 'UsabilityIssueInSmallScreens',
    weatherApp: 'NodeJSWeatherApplication',
    phpEnabled: 'PHPEnablementPlugin',
    bizEvents: 'BizEventsPlugin'
};

@Injectable()
export class PluginService {
  private BASE_URL = environment.serverUrl + 'plugins';
  public readonly pluginScenarios = new BehaviorSubject<ProblemScenarios>(new ProblemScenarios());

  constructor(private http: HttpClient) { }

  public refreshPluginsList(): Observable<string[]> {
    return this.http.get(this.BASE_URL + '/enabled')
      .pipe(map((response) => {
        this.updatePluginToScenarioMapping(response as string[]);
        return response as string[];
      }));
  }

  private updatePluginToScenarioMapping(plugins: string[]) {
    const scenarios = this.pluginScenarios.value;
    scenarios.realTimeUpdateRecentBooking = plugins.includes(PROBLEM_PATTERNS.webPageRequestSpamming);
    scenarios.max3Travelers = plugins.includes(PROBLEM_PATTERNS.max3Travelers);
    scenarios.ABtesting = plugins.includes(PROBLEM_PATTERNS.ABtesting);
    scenarios.scrollOnReview = plugins.includes(PROBLEM_PATTERNS.scrollOnReview);
    scenarios.weatherApp = plugins.includes(PROBLEM_PATTERNS.weatherApp);
    scenarios.phpEnabled = plugins.includes(PROBLEM_PATTERNS.phpEnabled);
    scenarios.bizEvents = plugins.includes(PROBLEM_PATTERNS.bizEvents);
    this.pluginScenarios.next(scenarios);
  }
}
