<app-main-layout class="search-page" *ngIf="journey">
  <div class="thumbnail" [style.background-image]="'url('+journey.images.currentPath+')'"></div>
  <main class="page-section">
    <div class="widget journey-stats">
      <app-journey-summary [journey]="journey"></app-journey-summary>

      <div *ngIf="phpEnabled">
        <h3>Rating</h3>
        <p>{{ journey.averageTotal }}</p>
      </div>

      <div *ngIf="journeyPostCount > 0">
        <h3>Blog</h3>
        <a [href]="blogUrl + '?tag=' + journey.id">Posts: {{journeyPostCount}}</a>
      </div>

      <div class="journey-actions">
        <span>Travelers:</span>
        <form [formGroup]="bookNowForm">
          <select formControlName="travelers">
            <option value="1">1 adult</option>
            <option value="2">1 adult+1 kid</option>
            <option value="3">1 adult+2 kids</option>
            <option value="4">2 adults</option>
            <option value="5">2 adults+1 kid</option>
            <option value="6">2 adults+2 kids</option>
          </select>

          <button name="book:booknow" [disabled]="disableBookNow" (click)="bookJourney()">Book Now</button>
        </form>
      </div>
    </div>

    <div *ngIf="weatherApp" class="widget">
      <h2>Weather forecast for Trip:</h2>
      <weather [link]="weatherLink"></weather>
  	</div>

    <app-recently-booked class="widget" [realTimeUpdate]="realTimeUpdateRecentBookings"></app-recently-booked>
  </main>
</app-main-layout>
