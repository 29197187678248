<footer class="page-section footer-section">
  <div>
      <img class="footer-logo" src="/assets/imgs/easytravel-logo.svg">
  </div>
  <div class="footer-info">
    <nav class="footer-links">
      <a id="footer:contact" routerLink="/contact">Contact us</a>
      <a id="footer:terms" (click)="endSession()">Terms &amp; conditions</a>
      <a id="footer:policy" routerLink="/home">Privacy policy</a>
    </nav>
    <p class="footer-copyright">&copy; 2018 Dynatrace. All rights reserved.</p>
  </div>
  <address class="social-links">
    <a id="fotter:facebook" href="https://www.facebook.com/Dynatrace/" class="icon-facebook" target="_blank">facebook</a>
    <a id="footer:twitter" href="https://twitter.com/Dynatrace" class="icon-twitter" target="_blank">twitter</a>
    <a id="footer:instagram" href="https://www.instagram.com/dynatrace/" class="icon-instagram" target="_blank">instagram</a>
  </address>
</footer>
