import {Component, Input, OnInit} from '@angular/core';
import {Journey} from '@models/journey';

@Component({
  selector: 'app-journey-summary',
  templateUrl: './journey-summary.component.html',
  styleUrls: ['./journey-summary.component.css']
})
export class JourneySummaryComponent implements OnInit {
  @Input()
  public journey: Journey;
  @Input()
  public additionalEntries?: {name: string, value: string}[] = [];

  constructor() { }

  ngOnInit() {
  }

}
