export class BookingRequest {
    journeyId: string;
    username: string;
    creditcard: string;
    amount: string;
    travellers: string;

    constructor (journeyId: string, username: string, creditcard: string, amount: string, travellers: string) {
        this.journeyId = journeyId;
        this.username = username;
        this.creditcard = creditcard;
        this.amount = amount;
        this.travellers = travellers;
    }
}
