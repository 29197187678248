import { Injectable } from '@angular/core';
import { Event } from '@models/event';

export interface JSONObject { [k: string]: JSONValue; }
export type Primitive = string | number | boolean;
export type JSONArray = JSONValue[];
export type JSONValue = JSONArray | JSONObject | Primitive;

declare global {
  interface Window {
    dynatrace?: {
      sendEvent(name: string, attributes: JSONObject): void;
      sendBizEvent(name: string, attributes: JSONObject): void;
    };
    dtrum?: {
      reportError(error: Error): void;
    };
  }
}

const dynatrace = window.dynatrace;
const dtrum = window.dtrum;

@Injectable()
export class EventsService {

  public createS01(searchedDestination: string | { name: string; }): Event {
    let searchedDestinationValue = searchedDestination;
    if (typeof searchedDestinationValue === 'object') {
      searchedDestinationValue = searchedDestinationValue.name;
    }
    return new Event(
      'Search for Destination',
      { 'searched_destination': searchedDestinationValue }
    );
  }

  public sendEvent(event: Event) {
    if (typeof dynatrace?.sendEvent === 'function') {
      try {
        dynatrace.sendEvent(event.name, event.attributes);
      } catch (e) {
        dtrum.reportError(e);
      }
    }
  }
}
