import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Booking } from '@models/booking';
import { BookingRequest } from '@models/bookingRequest';
import { BookingResponse } from '@models/bookingResponse';
import { environment } from '../../environments/environment';
import { DataService } from '@services/data.service';

@Injectable()
export class BookingService {

  private BASE_URL = environment.serverUrl + 'bookings/';

  constructor(private http: HttpClient, private dataService: DataService) { }

  public doBooking(bookingRequest: BookingRequest): Observable<BookingResponse> {
    return this.http.post(this.BASE_URL, bookingRequest)
      .pipe
      (
        catchError(this.handleError('doBooking', new BookingResponse()))
      )
      .pipe(map((response) => response as BookingResponse));
  }

  public getBookingById (id: number): Observable<Booking> {
    return this.http.get(this.BASE_URL + id)
      .pipe
      (
        catchError(this.handleError('getBookingById', {}))
      )
      .pipe(map((response) => response as Booking));
  }

  public getRecentBookings(limit: number): Observable<Booking[]> {
    return this.http.get(this.BASE_URL + 'recent/' + limit)
      .pipe
      (
        catchError(this.handleError('getRecentBookings', []))
      )
      .pipe(map((response) => response as Booking[]));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      this.dataService.errorMessage = error.error;

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
