import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EventsService } from '@services/events.service';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { SlideshowModule } from 'ng-simple-slideshow';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from '@components/home/home.component';
import { LocationService } from '@services/location.service';
import { JourneyService } from '@services/journey.service';
import { UserService } from '@services/user.service';
import { BookingService } from '@services/booking.service';
import { CreditCardValidationService } from '@services/creditcard-validation.service';
import { PluginService } from '@services/plugins.service';
import { ConfigService } from '@services/config.service';
import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@components/footer/footer.component';
import { HeroComponent } from '@components/hero/hero.component';
import { HomeMainComponent } from '@components/home-main/home-main.component';
import { FootnoteComponent } from '@components/footnote/footnote.component';
import { GalleryComponent } from '@components/gallery/gallery.component';
import { LoginComponent } from '@components/login/login.component';
import { LoginFormComponent } from '@components/login-form/login-form.component';
import { SearchComponent } from '@components/search/search.component';
import { MainLayoutComponent } from '@components/main-layout/main-layout.component';
import { OfferComponent } from '@components/offer/offer.component';
import { OfferStatComponent } from '@components/offer-stat/offer-stat.component';
import { OffersListComponent } from '@components/offers-list/offers-list.component';
import { SignupComponent } from '@components/signup/signup.component';
import { SignupFormComponent } from '@components/signup-form/signup-form.component';
import { ContactComponent } from '@components/contact/contact.component';
import { JourneyComponent } from '@components/journey/journey.component';
import { RecentlyBookedComponent } from '@components/recently-booked/recently-booked.component';
import { BookJourneyComponent } from '@components/book-journey/book-journey.component';
import { BillingComponent } from '@components/billing/billing.component';
import { WeatherComponent } from '@components/weather/weather.component';
import { ErrorPageComponent } from '@components/error-page/error-page.component';
import { AppErrorHandler } from './app-error-handler';
import { DataService } from '@services/data.service';
import { SafePipe } from '@pipes/safe.pipe';
import { DatePipe } from '@angular/common';
import { JourneySummaryComponent } from '@components/journey-summary/journey-summary.component';
import { AdobeService } from '@services/adobe.service';
import { BizEventService } from '@services/bizevent.service';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    DpDatePickerModule,
    NguiAutoCompleteModule,
    SlideshowModule,
    FontAwesomeModule
  ],
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    HeroComponent,
    HomeMainComponent,
    FootnoteComponent,
    GalleryComponent,
    LoginComponent,
    LoginFormComponent,
    SearchComponent,
    MainLayoutComponent,
    OfferComponent,
    OfferStatComponent,
    OffersListComponent,
    SignupComponent,
    SignupFormComponent,
    ContactComponent,
    JourneyComponent,
    RecentlyBookedComponent,
    BookJourneyComponent,
    BillingComponent,
    WeatherComponent,
    SafePipe,
    ErrorPageComponent,
    JourneySummaryComponent
  ],
  providers: [
    LocationService,
    JourneyService,
    UserService,
    BookingService,
    CreditCardValidationService,
    PluginService,
    ConfigService,
    DataService,
    DatePipe,
    {provide: ErrorHandler, useClass: AppErrorHandler},
    AdobeService,
    BizEventService,
    EventsService
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
    library.addIcons(faLock, faUnlock);
  }
}
