import { Injectable } from '@angular/core';

export type WindowWithExtras = Window & {
  s: {[key: string]: string};
};

@Injectable()
export class AdobeService {
  constructor() {
    (window as unknown as WindowWithExtras).s = {
      pageName: '',
      pageURL: '',
      pageType: '',
      eVar1: '',
      eVar2: '',
      eVar3: '',
      eVar4: '',
      prop1: '',
      prop2: ''
    };
  }

  public setSelectedJourney(value: string) {
    (window as unknown as WindowWithExtras).s.prop1 = value;
  }

  public setJourneyPrice(value: string) {
    (window as unknown as WindowWithExtras).s.prop2 = value;
  }

  public setJourneyPassengers(numberOfPassengers: string) {
    (window as unknown as WindowWithExtras).s.eVar4 = numberOfPassengers;
  }

  public setPageParams(pageName: string, pageURL: string, pageType: string) {
    (window as unknown as WindowWithExtras).s.pageName = pageName;
    (window as unknown as WindowWithExtras).s.pageURL = pageURL;
    (window as unknown as WindowWithExtras).s.pageType = pageType;
  }

  public setSearchParams(keyword: string, dateFrom: string, dateTo: string, numberOfPassengers: string) {
    (window as unknown as WindowWithExtras).s.eVar1 = keyword;
    (window as unknown as WindowWithExtras).s.eVar2 = dateFrom;
    (window as unknown as WindowWithExtras).s.eVar3 = dateTo;
    (window as unknown as WindowWithExtras).s.eVar4 = numberOfPassengers;
  }
}
