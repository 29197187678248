import { Component, OnInit } from '@angular/core';
import { Journey } from '@models/journey';
import { JourneyService } from '@services/journey.service';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.css']
})
export class HomeMainComponent implements OnInit {

  public specialOffers: Journey[] = [];
  public activityOffers: Journey[] = [];

  constructor(private journeyService: JourneyService) { }

  ngOnInit() {

    this.journeyService.getSpecialOffers()
      .subscribe(journeys => {
        this.specialOffers = journeys;
      });

    this.journeyService.getRecommendation()
      .subscribe(journeys => {
        this.activityOffers = journeys.slice(0, 3);
      });
  }
}
