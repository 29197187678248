<div id="summary" class="journey-stats">
  <h2>{{ journey.name }}</h2>

  <div>
    <h3>Journey</h3>
    <p>{{ journey.start }} — {{ journey.destination }}</p>
  </div>

  <div>
    <h3>Period</h3>
    <p>{{ journey.fromDate | date }} — {{ journey.toDate | date }}</p>
  </div>

  <div>
    <h3>Organized by</h3>
    <p>{{ journey.tenant }}</p>
  </div>

  <div>
    <h3>Amount</h3>
    <p>{{ journey.amount }}</p>
  </div>

  <div *ngFor="let entry of additionalEntries">
    <h3>{{entry.name}}</h3>
    <p>{{ entry.value.trim() ? entry.value : '-' }}</p>
  </div>
</div>
