import { WebTracerProvider } from '@opentelemetry/web';
import { UserInteractionPlugin } from '@opentelemetry/plugin-user-interaction';
import { DocumentLoad } from '@opentelemetry/plugin-document-load';
import { SimpleSpanProcessor } from '@opentelemetry/tracing';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { CollectorTraceExporter } from '@opentelemetry/exporter-collector';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request/build/src/xhr';
import { FetchPlugin } from '@opentelemetry/plugin-fetch';

export function enableOpenTelemetry(baseUrl: string) {
  const provider = new WebTracerProvider({
    plugins: [
      new DocumentLoad(),
      new UserInteractionPlugin(),
      new XMLHttpRequestInstrumentation({
        ignoreUrls: [/sockjs-node/],
      }),
      new FetchPlugin({
        ignoreUrls: [/sockjs-node/],
      }),
    ],
  });

  // provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
  const collectorConfig = {
    url: `${baseUrl}opentelemetry`,
    serviceName: 'easytravel-angular',
  };
  provider.addSpanProcessor(new SimpleSpanProcessor(new CollectorTraceExporter(collectorConfig)));

  provider.register({
    contextManager: new ZoneContextManager(),
  });
}
