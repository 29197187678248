import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '@services/user.service';
import { Loginuser } from '@models/loginUser';
import { Observable } from 'rxjs';
import { BizEventService } from '@services/bizevent.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit {

  @ViewChild('username', { static: true }) username: ElementRef;
  @Output() authorized = new EventEmitter<any>();
  public autoFill = false;
  public selectedLogin: Loginuser;

  constructor(
    private userService: UserService,
    private bizEventService: BizEventService
  ) { }

  ngOnInit() {
  }

  login(username: string, password: string): void {
    let bizEvent = this.bizEventService.createS12(username);
    this.bizEventService.sendBizEvent(bizEvent);

    this.userService.authenticate({ username, password })
      .subscribe(user => {
        this.authorized.emit({ user });
      });
  }

  autoLogin(login) {
    if (this.autoFill) {
      this.login(login.name, login.password);
    }
  }

  toggleAutofill() {
    this.autoFill = !this.autoFill;
    this.username.nativeElement.focus();
  }

  autoFillLogins = (keyword: string): Observable<Loginuser[]> => {
    if (this.autoFill) {
      return this.userService.searchUsers(keyword);
    } else {
      return new Observable((observer) => {
        observer.next(new Array<Loginuser>());
        observer.complete();
      });
    }
  }

}
