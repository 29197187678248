import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventsService } from '@services/events.service';
import { Observable } from 'rxjs';
import { LocationService } from '@services/location.service';
import { Location } from '@models/location';
import { ActivatedRoute, Router } from '@angular/router';
import { AdobeService } from '@services/adobe.service';
import { BizEventService } from '@services/bizevent.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.css']
})
export class HeroComponent implements OnInit, OnDestroy {

  constructor(
    private locationService: LocationService,
    private route: ActivatedRoute,
    private router: Router,
    private adobeService: AdobeService,
    private bizEventService: BizEventService,
    private eventsService: EventsService
  ) { }

  public fromDate: 'string';
  public toDate: 'string';
  public numberOfPeople: 'string';
  public selectedLocation: 'string';

  public subParams: any;

  datePickerConfig = {
    format: 'YYYY-MM-DD'
  };

  ngOnInit() {
    this.subParams = this.route.queryParams.subscribe(params => {
      this.fromDate = params.fromDate || '';
      this.toDate = params.toDate || '';
      this.numberOfPeople = params.numberOfPeople || '';
      this.selectedLocation = params.selectedLocation || '';
    });
  }

  ngOnDestroy() {
    this.subParams.unsubscribe();
  }

  locations = (keyword: string): Observable<Location[]> => {
    return this.locationService.searchLocations(keyword);
  }

  searchResults() {
    const queryParams = {};
    let location = '';

    if (this.fromDate) { Object.assign(queryParams, { fromDate: this.fromDate }); }
    if (this.toDate) { Object.assign(queryParams, { toDate: this.toDate }); }
    if (this.numberOfPeople) { Object.assign(queryParams, { numberOfPeople: this.numberOfPeople }); }
    if (this.selectedLocation) {
      Object.assign(queryParams, { selectedLocation: this.selectedLocation });
      location = this.selectedLocation.toString();
    }

    this.adobeService.setSearchParams(location, this.fromDate, this.toDate, this.numberOfPeople);

    let bizEvent = this.bizEventService.createS02(this.selectedLocation, this.fromDate, this.toDate, this.numberOfPeople);
    this.bizEventService.sendBizEvent(bizEvent);

    const event = this.eventsService.createS01(this.selectedLocation);
    this.eventsService.sendEvent(event);

    this.router.navigate(['/search'], { queryParams });
  }

}
