import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { BookingService } from '@services/booking.service';
import { Booking } from '@models/booking';

const MS_IN_DAY = 864e5;

@Component({
  selector: 'app-recently-booked',
  templateUrl: './recently-booked.component.html',
  styleUrls: ['./recently-booked.component.css']
})
export class RecentlyBookedComponent implements OnInit, OnDestroy {

  constructor(private bookingService: BookingService) { }

  private recentBookingFetchLimit = 3;
  private timerId: NodeJS.Timer;

  public recentBookings: Booking[];
  public fOffer: any;

  @Input('realTimeUpdate')
  set realTimeUpdate(value: boolean) {
    this.triggerUpdateMode(value);
  }

  ngOnInit () {
    this.updateRecentBooking(this);
  }

  triggerUpdateMode(realTimeUpdate: boolean): void {
    if (realTimeUpdate) {
      const udpateInterval = 100;
      this.timerId = setInterval(this.updateRecentBooking, udpateInterval, this);
    }
  }

  ngOnDestroy () {
    clearInterval(this.timerId);
  }

  updateRecentBooking(self) {
    self.bookingService.getRecentBookings(self.recentBookingFetchLimit).subscribe(
      res => {
        if (self.recentBookings !== res) {
          self.recentBookings = res;
        }
      }
    );
  }

  getFormattedPrice(booking: Booking) {
    return Math.floor(booking.journey.amount);
  }

  getBookingDurationInDays(booking: Booking) {
    return Math.round((booking.journey.toDate - booking.journey.fromDate) / MS_IN_DAY);
  }
}
