<main class="page-section main-section">
  <div class="main-widget">
    <app-offers-list title="Special offers" [offers]="specialOffers" inline="true" type="specialOffers"></app-offers-list>
  </div>

  <app-recently-booked class="main-widget"></app-recently-booked>

  <div class="main-widget">
    <app-offers-list title="Activities and Experiences" [offers]="activityOffers" inline="true" type="activityOffers"></app-offers-list>
  </div>
</main>
