import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.service';
import { BizEventService } from '@services/bizevent.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  public openMenu = false;

  constructor(
    private eRef: ElementRef,
    private userService: UserService,
    private router: Router,
    private bizEventService: BizEventService
  ) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.openMenu = false;
    }
  }

  getUsername() {
    const user = this.userService.session().user;
    return user ?
      user.firstName + ' ' + user.lastName
      : '';
  }

  isAuthorized() {
    return this.userService.isAuthorized();
  }

  logout() {
    let bizEvent = this.bizEventService.createS13(this.userService.session().user.email, this.userService.session().user.loyaltyStatus);
    this.bizEventService.sendBizEvent(bizEvent);

    this.userService.logout()
      .then(() => {
        this.router.navigate(['/login']);
      });
  }

  isActiveRoute(route: string): boolean {
    return this.router.url === route;
  }
}
